.products-page-container {
  /* background-color: #f2f2f2; */
  height: 440px;
  background-image: url("../../assets/images/test4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: brightness(0.7);
}

.products-page-text {
  width: 100%;
  position: absolute;
  /* top: 45%; */
  top: 380px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.products-page-text h1 {
  color: #fff;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
}
