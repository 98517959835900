.my-container {
  background-color: rgb(253, 179, 179);
  /* background-color: rgb(162, 0, 0); */
  border-bottom: 2px solid rgb(162, 0, 0);
  font-weight: 500;
  /* transition: 0.3s; */
  background-image: url("../../assets/images/test4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: 999;
}

.my-container-scrolled {
  /* background-color: rgb(254, 181, 181); */
  background-color: rgb(162, 0, 0);
  border-bottom: 2px solid rgb(162, 0, 0);
  font-weight: 500;
  transition: 0.3s;
  z-index: 999;
}

.my-container-scrolled .nav-links {
  background-color: rgb(162, 0, 0);
  color: #fff;
}

.my-container-scrolled .nav-links:hover {
  background-color: rgba(255, 151, 151, 0.5);
  color: #fff;
}

.my-container-scrolled .nav-links-active {
  background-color: rgba(255, 151, 151, 0.5);
  color: #fff;
}

.my-container-scrolled .nav-links-active:hover {
  background-color: rgba(255, 151, 151, 0.5);
  color: #fff;
}

.nav-links {
  background-color: rgba(255, 151, 151, 0.5);
  padding: 10px;
  border-radius: 4px;
  color: #181818;
  transition: 0.3s;
  font-weight: 500;
}

.nav-links:hover {
  background-color: rgb(162, 0, 0);
  padding: 10px;
  border-radius: 4px;
}

.nav-links-active {
  background-color: rgb(162, 0, 0);
  padding: 10px;
  border-radius: 4px;
  color: #f2f2f2;
  transition: 0.3s;
  font-weight: bold;
}

.nav-links-active:hover {
  background-color: rgb(162, 0, 0);
  padding: 10px;
  border-radius: 4px;
  color: #fff;
}
