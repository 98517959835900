.products-container {
  display: flex;
  flex-direction: column;
  /* background-color: #ffb1b1; */
  justify-content: center;
  align-items: center;
  min-width: 100%;
  padding: 0 !important;
}

.products-text-container {
  width: 100%;
  /* background-color: rgba(255, 151, 151, 0.5); */
  background-color: #a20000;
  text-align: center;
  padding: 12px;
}

.products-carousel-container {
  width: 100%;
  /* background-color: #ffd8d8; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 700px;
}

.products-container h2 {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .products-container h2 {
    font-size: 1.5rem;
  }
}

/* Add a class to target the image */
.responsive-image {
  height: auto;
  max-width: 100%;
  cursor: pointer !important;
}

/* Apply specific styles to the image for different screen sizes */
@media (max-width: 767px) {
  /* For screens smaller than 768px */
  .responsive-image {
    width: 100%; /* Set the width to 100% */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* For screens between 768px and 991px */
  .responsive-image {
    width: 80%; /* Adjust the width as needed */
  }
}

@media (min-width: 992px) {
  /* For screens larger than 991px */
  .responsive-image {
    width: 800px; /* Set a fixed width for larger screens */
  }
}
