.products-list-container {
  background-color: #ffffff;
}

.card-clickable {
  background-color: transparent;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  height: 380px;
  justify-content: center;
  align-items: center;
}

.card-clickable:hover {
  cursor: pointer;
  /* background-color: #ffdede; */
  background-color: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.3);
}
