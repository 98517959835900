.products-container {
  display: flex;
  flex-direction: column;
  /* background-color: #ffb1b1; */
  justify-content: center;
  align-items: center;
  min-width: 100%;
  padding: 0 !important;
}

.products-text-container {
  width: 100%;
  /* background-color: rgba(255, 151, 151, 0.5); */
  background-color: #d41111;
  text-align: center;
  padding: 12px;
}

.products-carousel-container {
  width: 100%;
  /* background-color: #ffd8d8; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 700px;
}

.products-container h2 {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .products-container h2 {
    font-size: 1.5rem;
  }
}
