.body-container {
  /* background-color: #f2f2f2; */
  height: 600px;
  background-image: url("../../assets/images/test4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: brightness(0.7);
}

.my-text {
  width: 100%;
  position: absolute;
  /* top: 45%; */
  top: 450px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.my-text h1 {
  color: #fff;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .my-text h1 {
    font-size: 2.3rem;
  }
  .my-text {
    top: 250px;
  }
  .body-container {
    height: 400px;
  }
}
