.info-board-container {
  height: auto;
  background-color: rgb(162, 0, 0);
}

.info-icon-box {
  padding: 10px 0;
  position: relative;
}

.info-icon-details .icon-title {
  font-size: 15px;
  margin-bottom: 5px;
  color: #fff;
}

.info-icon-details .icon-desc {
  font-size: 12px;
  margin-bottom: 5px;
  color: #aaaaaa;
}

a {
  background: transparent;
  color: #aaa;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .info-board-container {
    display: none;
  }
}
