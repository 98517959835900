.top-line-container {
  height: 28px;
  /* background-color: rgb(38, 39, 97); */
  background-color: rgb(162, 0, 0);
}

ul {
  list-style-type: none;
}

li {
  margin: 0 0 200px 0;
  display: inline;
}

li:not(:last-child) {
  margin-right: 12px;
}

@media (max-width: 768px) {
  .top-line-responsive {
    justify-content: center !important;
  }
}
