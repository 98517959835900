.main-wrapper {
  display: flex;
  background-color: #ffffff;
  padding-top: 60px;
  min-height: 80vh;
}

.title-wrapper {
  display: flex;
  height: 80px;
  align-items: center;
}

.title-wrapper h5 {
  font-weight: bold;
  /* color: rgb(145, 145, 145); */
  color: #000;
}

.text-red {
  color: rgb(202, 0, 0);
}

.description-wrapper p {
  color: #000;
}
